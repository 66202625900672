<template>
  <HubModal
    id="dashboard-touchpoint-filter-modal"
    :title="$t('organisations.touchpoint', 2)"
    confirm-text="filters.applyFilters"
    :translate-title="false"
    :content-padding="false"
    @confirm="applyFilter"
    @cancel="cancelFilter"
  >
    <div class="my-4 justify-between px-6 md:flex md:pl-0">
      <div class="overflow-x-visible overflow-y-scroll py-2 pr-4 md:w-3/5 lg:max-h-[80vh]">
        <HubTree
          v-model:selection-keys="selectedNodes"
          :value="availableTouchpoints"
          :show-filter="false"
        />
      </div>

      <div class="mt-4 max-h-96 min-h-full overflow-y-scroll rounded-lg bg-blue-7 md:mt-0 md:w-2/5 lg:max-h-[60vh]">
        <div class="h-full p-3">
          <div class="flex items-baseline justify-between">
            <div class="items-baseline justify-between text-sm font-semibold text-grey-blue lg:flex">
              {{ selectedTouchpointTreeNodesCount }} {{ $t('selected') }}
              <span
                v-if="selectedTouchpointTreeNodesCount === 0"
                class="ml-1"
              >({{ $t('filters.showAll') }})</span>
            </div>
            <HubButton
              v-if="selectedTouchpointTreeNodesCount > 0"
              icon="i-mdi-remove-bold"
              icon-classes="!size-3"
              type="outlined-transparent"
              size="xs"
              :label="$t('clear')"
              @click="clearFilter"
            />
          </div>
          <div class="mt-4 hidden md:block">
            <div
              v-for="touchpoint of selectedTouchpointTreeNodes"
              :key="touchpoint.key"
              class="items-center justify-between text-sm text-grey-blue md:flex"
            >
              <p>{{ touchpoint.label }}</p>
              <UIcon
                name="i-mdi-remove-circle-outline"
                class="my-1 !size-5 cursor-pointer text-trublue hover:text-blue-3"
                @click="deselectTouchpoint(touchpoint.key)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </HubModal>
</template>

<script setup lang="ts">
import type { CollectionOfSelectedTreeNodes, TouchpointTreeNode } from '~/types/tree'
// internal types

const props = defineProps<{
  selectedTouchpoints?: Array<string> | string
  availableTouchpoints: Array<TouchpointTreeNode>
}>()
const emit = defineEmits<{
  (e: 'update:selected-touchpoints', selectedTouchpointKeys: Array<string>): void
  (e: 'update:selected-touchpoint-names', selectedTouchpointNames: Array<string> | undefined): void
  (e: 'update:model-value', arg1: boolean): void
}>()

// internal refs
const selectedNodes: Ref<CollectionOfSelectedTreeNodes | undefined> = ref()

// computed refs
const selectedTouchpointKeys: ComputedRef<Array<string>> = computed(() =>
  selectedNodes.value ? getSelectedKeysFromNodes(selectedNodes.value, false) : []
)
const selectedTouchpointTreeNodes: ComputedRef<Array<TouchpointTreeNode>> = computed(() => {
  if (!selectedTouchpointKeys.value) return []

  return props.availableTouchpoints.filter(touchpoint => selectedTouchpointKeys.value.includes(touchpoint.key))
})

const selectedTouchpointTreeNodesCount = computed(() => selectedTouchpointTreeNodes.value.length)

// watchers
watch(
  [() => props, () => props.availableTouchpoints],
  () => {
    if (!props.selectedTouchpoints) {
      emit('update:selected-touchpoint-names', undefined)
      return
    }

    selectedNodes.value = getSelectedNodesFromKeys(props.selectedTouchpoints, props.availableTouchpoints)
    emit(
      'update:selected-touchpoint-names',
      selectedTouchpointTreeNodes.value.map(t => t.label)
    )
  },
  {
    deep: true,
    immediate: true
  }
)

function deselectTouchpoint(touchpointKey: string) {
  if (!selectedNodes.value) return

  const newSelectedNodes = { ...selectedNodes.value }

  const splitKey = touchpointKey.split('-')
  const keyArray: Array<string> = []
  let newKey = ''
  for (const key of splitKey) {
    newKey = newKey + key
    keyArray.push(newKey)
    newKey = newKey + '-'
  }

  for (const key of keyArray) {
    newSelectedNodes[key] = { checked: false, partialChecked: true }
  }
  newSelectedNodes[touchpointKey] = { checked: false, partialChecked: false }

  const currentSelectedKeys = getSelectedKeysFromNodes(newSelectedNodes)
  selectedNodes.value = getSelectedNodesFromKeys(currentSelectedKeys, props.availableTouchpoints)
}

function applyFilter() {
  emit('update:selected-touchpoints', selectedTouchpointKeys.value)
  emit(
    'update:selected-touchpoint-names',
    selectedTouchpointTreeNodes.value.map(t => t.label)
  )
  emit('update:model-value', false)
}

function cancelFilter() {
  if (props.selectedTouchpoints) {
    selectedNodes.value = getSelectedNodesFromKeys(props.selectedTouchpoints, props.availableTouchpoints)
  }
  emit('update:model-value', false)
}

function clearFilter() {
  selectedNodes.value = undefined
}
</script>
